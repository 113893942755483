<template>
	<div @click="onClick" class="z-card" v-if="show">
		<div :class="{disabled}" class="card-content">
			<div class="image" :style="{ 'background-image': `url(${image ? image : require('./img/coursePlaceholder.png')})` }"></div>
			<div class="text-wrapper">
				<h3 class="z-fw-6" v-if="title">{{ title }} <span v-if="disabled"> (Pripravujeme)</span></h3>
				<div class="tag-wrapper" :v-if="tags" v-for="(tag, i) in tags" :key="i">
					<p class="z-fw-6">{{ tag }}</p>
				</div>
				<p class="description z-fw-1" v-if="description && description.length" v-html="$options.filters.truncate(description, 400, '...')"></p>
			</div>
		</div>

		<div class="footer">
			<slot name="footer-fluid"></slot>
			
			<div class="d-flex justify-content-between footer-sides">
				<div>
					<slot name="footer-left"></slot>
				</div>
				<div>
					<slot name="footer-right"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		image: {
			required: true
		},
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			required: false
		},
		tags: {
			type: Array,
			required: false
		},
		url: {
			type: String,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		show: {
			type: Boolean,
			required: false,
			default: true
		},
		isLocked: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		]),
	},
	methods: {
		onClick() {
			if(!this.isLoggedIn){
				localStorage.setItem('LAST_FROM_ROUTE',this.url)

			}

			if(this.isLocked) {
				this.EventBus.$emit('locked-content-accessed')
				return
			}
			window.location = this.disabled ? `#${this.url}` : this.url
		}
	}
}
</script>

<style lang="scss" scoped>
	a {
		color: #000000;
	}

	a:hover {
		text-decoration: none;
	}
	
	.z-card {
		width: 354px;
		transition-duration: 200ms;
		margin-bottom: 40px !important;

		border-radius: 0 0 10px 10px;
		border: 1px solid rgba(71, 29, 193, 0.05);

		display: flex;
		flex-wrap: wrap;
		overflow: hidden;

		.footer {
			padding: 15px 20px 20px 20px;
			align-self: flex-end;
			width: 100%;
		}

		.footer-sides {
			padding-top: 10px;
		}
	}

	.z-card:hover {
		box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.07);
		cursor: pointer;
	}

	.z-card.disabled {
		opacity: 0.5;
	}

	.card-content {
		width: 100%;

		.image {
			width: 100%;
			padding-top: 56.25%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}

		.text-wrapper {
			padding: 37px 19px 15px 35px;
			position: relative;

			h3 {
				font-size: 22px;
				margin-bottom: 15px;
			}

			.tag-wrapper {
				display: inline-block;
				padding: 10px 18px;
				background-color: #F0FAFD;
				border-radius: 40px;
				margin: 0px 8px 12px 0;

				p {
					font-size: 11px;
					line-height: 11px;
					margin: 0;
					color: #42C9F8;
				}
			}

			.description {
				font-size: 16px;
				line-height: 25px;
				color: #031D5B;

				margin-top: 18px;
			}
		}
	}
</style>